<template>
  <div class="row">
    <div class="col">
      <ul>
        <li v-for="item in alternateEven" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="col">
      <ul>
        <li v-for="item in alternateOdd" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBoxAlternatingContent',
  components: {},
  props: {
    content: {
      type: Array,
      default: Array,
    },
  },
  computed: {
    alternateEven() {
      // evenOrOdd number 0 or 1
      return this.content.filter((element, index) => {
        return index % 2 == 0
      })
    },
    alternateOdd() {
      // evenOrOdd number 0 or 1
      return this.content.filter((element, index) => {
        return index % 2 == 1
      })
    },
  },
}
</script>
