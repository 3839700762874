<template>
  <div class="p-4">
    <div class="p-5">
      <h1 class="mb-5 text-dark">
        <strong>Specialist</strong>
      </h1>

      <!-- selected services section -->
      <InfoBox :title="`Selected Service Requirement(s)`">
        <div class="container mt-4">
          <InfoBoxAlternatingContent
            v-if="serviceAndFeesInfo"
            :content="serviceAndFeesInfo"
          />
        </div>
      </InfoBox>

      <div class="row mt-5">
        <div class="col-12">
          <label class="text-dark">Completed teleconsultation?</label>

          <BaseRadioGroup
            v-model="specialist.is_teleconsult_completed"
            :error="specialistErrors.is_teleconsult_completed"
            :options="yesNoOptions"
            :label="`specialist_teleconsult`"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-5">
          <label class="text-dark">Completed TPD Report ?</label>

          <BaseRadioGroup
            v-model="specialist.is_report_completed"
            :error="specialistErrors.is_report_completed"
            :options="yesNoOptions"
            :label="`specialist_report`"
          />
        </div>
      </div>

      <div class="d-grid gap-2 col-2 mx-auto">
        <button
          type="button"
          class="btn btn-primary rounded-pill text-white my-3"
          @click="submit"
        >
          <strong>Save</strong>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import InfoBox from '@/components/TPD/Widgets/InfoBox.vue'
import InfoBoxAlternatingContent from '@/components/TPD/Widgets/InfoBoxAlternatingContent.vue'

export default {
  name: 'SpecialistTPDForm',
  components: {
    BaseRadioGroup,
    InfoBox,
    InfoBoxAlternatingContent,
  },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      specialist: {
        is_teleconsult_completed: false,
        is_report_completed: false,
      },

      serviceAndFeesInfo: [],

      specialistErrors: {},

      yesNoOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],

      pageReady: false,
    }
  },

  mounted() {
    if (this.$store.state.TPDAssessment) {
      this.specialist = this.$store.state.TPDAssessment
        .specialist_consultation ?? {
        is_teleconsult_completed: false,
        is_report_completed: false,
      }
      //update service and fees display
      this.$store.state.TPDAssessment.referral.service_requirement.ime_report
        ? this.serviceAndFeesInfo.push('Additional IME report')
        : ''
      this.$store.state.TPDAssessment.referral.service_requirement.mas_report
        ? this.serviceAndFeesInfo.push('Additional MAS report')
        : ''
      let found =
        this.$store.state.TPDAssessment.referral.service_requirement_choices.find(
          (x) =>
            x.service_type ===
            this.$store.state.TPDAssessment.referral.service_requirement
              .service_type
        )

      if (found) {
        this.serviceAndFeesInfo = this.serviceAndFeesInfo.concat(
          found.service_display_name.split(/[&,]/)
        )
      }
    }
    this.pageReady = true
  },

  methods: {
    async patchConsultation() {
      let payload = { id: this.$route.params.id, payload: this.specialist }
      await this.$store
        .dispatch('patchTPDSpecialistConsult', payload)

        .then((response) => {
          this.specialist = response.data
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: 'Specialist consultation updated successfully!',
          })
        })
        .catch((error) => {
          if (error.response) {
            this.specialistErrors = error.response.data
          } else if (error.request) {
            alert(`${error.request}`)
          } else {
            // Trigger toast text
            this.$root.toasts.push({
              status: 'danger',
              content: `Error: ${error.message}`,
            })
          }
        })
    },

    submit() {
      this.patchConsultation()
    },
  },
}
</script>

<style lang="scss" scoped></style>
